import { css } from "@emotion/core";
import { mq } from "../../../cssInJs";

export default {
  pageTiles: css({
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    margin: "2rem -18px",
    overflow: "hidden",
  }),
  pageTile__tile: css(
    mq({
      flex: "0 0 100%",
      maxWidth: "100%",
      paddingBottom: ["2rem", "4rem"],
      "&:nth-of-type(even)": {
        ".pageTile__bsyc__bg": mq({
          order: ["0", null, "2"],
        }),
        ".pageTile__bsyc__content__details": mq({
          order: "1",
        }),
      },
    })
  ),

  tile__content: css({
    "&:hover": {
      textDecoration: "none",
      "& .pageTile__bsyc__btn": {
        background: "#60895C",
      },
    },
  }),

  tile__flip__wrapper: css(
    mq({
      zIndex: "-1",
      display: ["block", "flex"],
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      height: "100%",
      cursor: "pointer",
      position: "relative",
      flexWrap: "wrap",
    })
  ),
  tile__bg: css(
    mq({
      height: "0",
      paddingTop: ["59%", "35%", "30%"],
      flex: "1 1 50%",
      overflow: "hidden",
      position: "relative",
    })
  ),

  tile__bg2: css(
    mq({
      height: "100%",
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      transition: "all .2s",
      position: "absolute",
      top: "0px",
      width: "100%",
    })
  ),

  tile__details: css({
    overflow: "hidden",
    flexGrow: "1",
    width: "100%",
    bottom: "0",
    flex: "1 1 50%",
    transition: "all .2s ease-in-out",
    position: "relative",
    "& .pageTile__bsyc__container": mq({
      position: ["static,", null, "absolute"],
      top: "50%",
      left: "50%",
      transform: ["none", null, "translate(-50%, -50%)"],
      width: "100%",
      //   padding: ["1rem", null, "0px"],
      "& .pageTile__bsyc__title": mq({
        fontSize: "2rem",
        lineHeight: ["1.3", "1.5"],
        margin: "0",
        paddingBottom: "1rem",
        color: "#333",
        fontWeight: "bold",
      }),
      "& .pageTile__bsyc__desc": {
        lineHeight: "1.5",
        fontSize: ".9rem",
        color: "#4C4C4C",
      },
    }),
  }),
};
